<template>
    <el-dialog v-loading="loader" :title="title" :visible.sync="visible" width="585px" class="p-2" :before-close="handleClose">
        <motivational-quote-form ref="form" :motivational-quote="motivationalQuote" @update="update" @add="add" @cancel="handleClose"/>
    </el-dialog>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import MotivationalQuoteForm from "@/views/Components/SuperAdmin/MotivationalQuote/Form";
export default {
    props: ['visible', 'motivationalQuote'],
    mixins:[generateErrorMessageMixin],
    components:{
        MotivationalQuoteForm
    },
    data() {
        return {
            loader: false,
        };
    },
    mounted(){
        
    },
    watch: {
        
    },
    computed:{
        title(){
            return this.motivationalQuote ? 'Edit Motivational Quote' : 'Add New  Motivational Quote';
        }
    },
    methods: {
        handleClose() {
            let validation = this.$refs.form.$refs.formValidator;
            validation.reset();
            this.$refs.form.setValues({});
            this.$emit('closeDialog')
        },
        async update({fd,id}) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('MotivationalQuoteModule/_updateMotivationalQuote',{fd,id});
                let {  data : {data,message } } = response;
                this.$notify.success({
                    title: 'Motivational Quote',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('updated');
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error,'Motivational Quote',validation)

            }
            this.loader = false;
        },
        async add(fd) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('MotivationalQuoteModule/_storeMotivationalQuote',{fd});
                let {  data : {data,message } } = response;
                this.$notify.success({
                    title: 'Motivational Quote',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('added');
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error,'Motivational Quote',validation)

            }
            this.loader = false;
        }
    },
};
</script>
  
<style scoped lang="scss"></style>
  