<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader">
            <div class="motivational-quote-form mt-1">
                <base-select name="status" label="Status*" v-model="form.status" :options="statusOptions"
                    :rules="{ custom_required: true }">
                </base-select>
                <base-input name="name" label="Name*" v-model="form.name" :rules="{ custom_required: true }"
                    placeholder="Name">
                </base-input>
                <validation-provider :rules="{ custom_required: true }" name="description"
                    v-slot="{ errors, valid, invalid, validated }">
                    <div class="form-group">
                        <label class="form-control-label">Description*</label>
                        <textarea v-model="form.description" class="form-control" rows="5"
                            :class="[{ 'is-invalid': invalid && validated }]"></textarea>
                        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                            {{ errors[0] }}
                        </div>
                    </div>
                </validation-provider>
            </div>
            <div class="text-right mb-2">
                <base-button type="gray" size="xl" class="px-2 mr-3" :disabled="loader" @click="$emit('cancel')">Cancel</base-button>
                <base-button native-type="submit" type="dark-blue" size="xl" :disabled="loader">{{ submitButton }}</base-button>
            </div>
        </form>
    </validation-observer>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    props: {
        motivationalQuote: Object,
        loader: {
            type: Boolean,
            default: false
        }
    },
    components: {
    },
    mixins: [generateErrorMessageMixin],
    data() {
        return {
            form: {
                name: '',
                description: '',
                status: '',
            },
            statusOptions: [
                {
                    label: 'Active',
                    value: 1,
                },
                {
                    label: 'Inactive',
                    value: 0,
                }
            ],
        };
    },
    mounted() {
        if (this.motivationalQuote) {
            this.setValues(this.motivationalQuote);
        }
    },
    watch: {
        'motivationalQuote': function (newValue) {
            this.setValues(newValue);
        }
    },
    methods: {
        setValues(value) {
            this.form.name = value?.name || '';
            this.form.description = value?.description || '';
            this.form.status = value?.status >=0 ?  value.status  : '';
        },
        async onSubmit() {
            if(this.motivationalQuote) this.$emit('update', {fd:this.form,id:this.motivationalQuote.id});
            else this.$emit('add', this.form);
        }
    },
    computed:{
        submitButton(){
            return !this.motivationalQuote ? 'Save' : 'Update';
        }
    }
};
</script>
  
<style lang="scss">
.motivational-quote-form {
    .form-group{
        margin-bottom: 0.65rem !important;
    }
    input {
        &.form-control {
            height: calc(1.5em + 1.25rem + 2px);
        }
    }
}
</style>
  