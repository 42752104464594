<template>
    <el-dialog :visible.sync="visible" title="View Info" width="500px" :before-close="handleClose" class="motivational-quote-show">
        <div class="border-top" v-if="motivationalQuote">
            <div class="mt-4 d-flex align-items-end justify-content-between">
                <p class="custom-status" :class="motivationalQuote.status ? 'active' : 'inactive'">{{ motivationalQuote.status ? 'Active' : 'Inactive' }}</p>
                <base-button type="dark-blue" class="py-1" outline size="xl" @click="$emit('edit',motivationalQuote)">Edit</base-button>
            </div>
            <div class="__details mt-4">
                <p  class="__description">{{ motivationalQuote.description }}</p>
                <p class="__heading">{{ motivationalQuote.name }}</p>
            </div>
        </div>
    </el-dialog>
</template>
  
<script>
export default {
    props: ['visible', 'motivationalQuote'],
    components: {
    },
    data() {
        return {
            loader: false,
        };
    },
    mounted() {

    },
    watch: {

    },
    computed: {

    },
    methods: {
        handleClose() {
            this.$emit('closeDialog')
        },
    },
};
</script>
  
<style scoped lang="scss">
.motivational-quote-show{
    .__details{
        .__heading{
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        .__description{
            font-size: 12px;
            font-weight: normal;
            margin-bottom: 0.5rem;
        }
    
    }
}
</style>
  